import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'
import { palette } from 'styled-theme'
import { Box, Flex } from '@rebass/grid'
import Text, { formatLineBreak } from '../../atoms/Text'
import Link from '../../atoms/Link'
import { datetimeFormatter } from '../../../utlils/datetime'

const REGEX_URL = /http[s]*:\/\/[^\s]+/g

const StyledBox = styled(Box)`
  background: ${ifProp({ align: 'left' }, palette('primary', 3), palette('primary', 5))};
  border-radius: 0.5rem;
  padding: 0.8rem 0.8rem 0.4rem;
  position: relative;
  box-shadow: 0 0.125rem 0.125rem ${palette('grayscale', 2)};
  word-break: break-word;
  max-width: 80%;
  min-width: 30%;
  ${ifProp({ align: 'left' }, css`
    border-top-left-radius: 0;
    margin-left: 1.2rem;
  `, css`
    border-top-right-radius: 0;
    margin-right: 1.2rem;
  `)}
  ::before {
    content: '';
    position: absolute;
    top: 0;
    border-bottom: 1.2rem solid transparent;
    ${ifProp({ align: 'left' }, css`
      left: 0;
      border-right: 1rem solid ${palette('primary', 3)};
      margin-left: -1rem;
    `, css`
      right: 0;
      border-left: 1rem solid ${palette('primary', 5)};
      margin-right: -1rem;
    `)}
  }
`

const LoadingDots = styled.div`
  width: 40px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side, #F5F5F5 90%, #F5F5F500);
  background: 
    var(--_g) 0%   50%,
    var(--_g) 50%  50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 50%;
  animation: l3 1s infinite linear;
  @keyframes l3 {
    20%{background-position:0%   0%, 50%  50%,100%  50%}
    40%{background-position:0% 100%, 50%   0%,100%  50%}
    60%{background-position:0%  50%, 50% 100%,100%   0%}
    80%{background-position:0%  50%, 50%  50%,100% 100%}
  }
`

const Message = ({ message, align, ...props }) => {
  const { sender = {}, content, send_time } = message
  const { display_name } = sender
  const matchResults = content.match(REGEX_URL)

  const isBotTyping = useMemo(() => content === '正在輸入中...', [content])

  if (isBotTyping) {
    return (
      <StyledBox
        align="left"
        style={{ padding: '0.5rem 1rem', minWidth: 'auto' }}
        {...props}
      >
        <LoadingDots />
      </StyledBox>
    )
  }

  return (
    <StyledBox align={align} {...props}>
      <Flex>
        <Text bold variant="body1" palette="grayscale" paletteIndex={align === 'left' ? 4 : 1}>{display_name}</Text>
      </Flex>
      <Box mt="0.4rem">
        <Text variant="body1" palette="grayscale" paletteIndex={align === 'left' ? 5 : 0}>
          {matchResults?.length ? (
            content.split(REGEX_URL).map((str, j) => (
              <React.Fragment key={j}>
                {formatLineBreak(str)}
                {j < matchResults.length && (
                  <Link
                    href={matchResults[j]}
                    palette={align === 'left' ? 'secondary' : 'primary'}
                  >
                    {matchResults[j]}
                  </Link>
                )}
              </React.Fragment>
            ))
          ) : content}
        </Text>
      </Box>
      <Flex justifyContent="flex-end" mt="0.4rem">
        <Text variant="body3" palette="grayscale" paletteIndex={align === 'left' ? 4 : 2}>
          {datetimeFormatter(send_time, 'h:mm A')}
        </Text>
      </Flex>
    </StyledBox>
  )
}

Message.propTypes = {
  message: PropTypes.shape({
    sender: PropTypes.shape({
      display_name: PropTypes.string,
    }),
    content: PropTypes.string,
    send_time: PropTypes.number,
  }),
  align: PropTypes.oneOf(['left', 'right']),
}

Message.defaultProps = {
  message: {},
  align: 'left',
}

export default Message
